<template>
  <div id="data-view" :key="key">
    <dv-full-screen-container>
      <top-header />
      <div class="main-content">
        <ranking-board
          :hdangerCate="hdangerCate"
          :hdangerStatus="hdangerStatus"
          :duty="duty"
          :dutyCount="dutyCount"
        />

        <div class="block-top-bottom-content">
          <div class="block-top-content">
            <scroll-board :hdangerList="hdangerList"  class="List-heigth"/>
            <scroll-riskpoint :dangerRecList="dangerRecList" class="List-heigth" />
            <scroll-crowd :personnelRecList="personnelRecList" class="List-heigth" />
            <scroll-Police :armyRecList="armyRecList" class="List-heigth" />

            <column-chart :hdangerMonthList="hdangerMonthList" class="List-heigth" />
            <control-chart :controlMonthList="controlMonthList" class="List-heigth" />
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from "./topHeader";
import rankingBoard from "./rankingBoard";
import scrollBoard from "./scrollBoard";
import scrollRiskpoint from "./risk_point";
import columnChart from "./column-chart";
import controlChart from "./control-chart";
import scrollCrowd from "./scroll_crowd";
import scrollPolice from "./scroll_Police";
import { bisituation } from "../../../assets/request/api";

export default {
  name: "DataView",
  components: {
    topHeader,
    rankingBoard,
    scrollBoard,
    scrollRiskpoint,
    columnChart,
    controlChart,
    scrollCrowd,
    scrollPolice,
  },
  data() {
    return {
      key: 1,
      armyRecList: [],
      dangerRecList: [],
      personnelRecList: [],
      hdangerList: [],
      hdangerCate: [],
      hdangerStatus: [],
      hdangerMonthList: [],
      controlMonthList: [],
      duty: [],
      dutyCount: 0,
    };
  },
  created() {
    this.way_Data();
  },
  methods: {
    way_Data() {
      bisituation().then((res) => {
        let {
          armyRecList,
          dangerRecList,
          personnelRecList,
          hdangerList,
          hdangerCate,
          hdangerStatus,
          hdangerMonthList,
          controlMonthList,
          duty,
          dutyCount,
        } = res.data;
        this.hdangerList = hdangerList;
        this.armyRecList = armyRecList;
        this.dangerRecList = dangerRecList;
        this.personnelRecList = personnelRecList;
        this.hdangerCate = hdangerCate;
        this.hdangerStatus = hdangerStatus;
        this.hdangerMonthList = hdangerMonthList;
        this.duty = duty;
        this.controlMonthList = controlMonthList;
        this.dutyCount = dutyCount;
      });
    },
  },
  mounted() {
    var _this = this;
    window.addEventListener("resize", function (e) {
      _this.$nextTick(() => {
        // window.location.reload();
        _this.way_Data();

        _this.key++;
        // console.log(_this.key)

        // console.log(_this.$refs.box)
        // _this.$refs.box.initWH();
      });
    });
  },
};
</script>

<style lang="less" scoped>
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url("../../../assets/img/bg3.png");
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    // flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    align-self: center;
    flex-wrap: wrap;
    height: 100%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;

    justify-content: space-between;
    // & > div{
    //   height: calc(100%/3) !important;
    // }
  }
  .column-chart-div {
    height: 34%;
    display: flex;
    justify-content: space-between;
  }
}
.List-heigth {
  height: 32%;
  overflow: hidden;
}
</style>
