<template>
  <div id="column-chart">
    <div class="ranking-board-title">风险管控月度统计</div>
    <!-- <dv-conical-column-chart class="dv-conical" :config="config" /> -->
    <dv-scroll-board :config="config" class="dv-conical" />
  </div>
</template>

<script>
export default {
  name: "control-chart",
  props: {
    controlMonthList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // config: {
      //   showValue: true,
      //   data: [],
      //   fontSize: 16,
      // },

      config: {
        data: [
          ["10月20日", "已巡检6次", "未巡检0次"],
          ["10月19日", "已巡检10次", "未巡检0次"],
          ["10月18日", "已巡检10次", "未巡检0次"],
          ["10月17日", "已巡检10次", "未巡检0次"],
          ["10月16日", "已巡检11次", "未巡检0次"],
          ["10月15日", "已巡检10次", "未巡检0次"],
        ],

        header: [],
        index: true,
        columnWidth: [50],
        align: ["center"],
        rowNum: 4,
        headerBGC: "#1981f6",
        headerHeight: 0,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },
  watch: {
    controlMonthList: function (newValue, oldVal) {
      // this.config.data = newValue;
      // console.log(newValue);
      // this.config = { ...this.config };
    },
    deep: true,
  },
};
</script>

<style lang="less" scoped>
#column-chart {
  // height: 32%;
  margin-top: 13px;

  width: calc(50% - 10px);
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 0 3px blue;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  box-sizing: border-box;
  // padding: 10px;
}
.ranking-board-title {
  padding-left: 10px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.dv-conical {
  height: 80%;
}
</style>
