<template>
  <div id="scroll-board">
    <div class="ranking-board-title">重点人群管控台账</div>
    <dv-scroll-board :config="config" />
  </div>
</template>

<script>
export default {
  name: "ScrollBoard",
  props: {
    personnelRecList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data() {
    return {
      config: {
            data: [
          ["张**）", "admin", "2022-10-20 15:08"],
          ["李**）", "admin", "2022-10-15 15:08"],
          ["王**）", "admin", "2022-10-12 15:08"],
          ["孙**）", "admin", "2022-10-01 15:08"],
        ],
        header: [],
        index: true,
        columnWidth: [50, 80],
        align: ["center"],
        rowNum: 4,
        headerBGC: "#1981f6",
        headerHeight: 0,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },

  watch: {
    personnelRecList: {
      handler(newValue, oldValue) {
        // console.log(newValue)
        // this.config.data = newValue;
        // this.config = { ...this.config };
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
#scroll-board {
  // height: 32%;
  margin-top: 13px;
  width: calc(50% - 10px);
  box-sizing: border-box;
  // margin-bottom: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  display: flex;
  flex-direction: column;
}
.ranking-board-title {
  padding-left: 10px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
</style>
