<template>
  <div id="top-header">
    <dv-decoration-8 class="header-left-decoration" />
    <dv-decoration-5 class="header-center-decoration" />
    <dv-decoration-8 class="header-right-decoration" :reverse="true" />
    <div class="center-title">平安经院安全管控态势</div>
    <!-- <div class="center-title">义乌市稠江街道城镇职校</div> -->
    <div class="rihth-title">
      {{ nowDate }} {{ weather }}
      <i
        style="margin-left: 10px"
        title="点击展开"
        @click="screen"
        class="el-icon-full-screen"
      ></i>
    </div>
  </div>
</template>

<script>
import screenfull from "screenfull";

import { indexgetWeather } from "../../../assets/request/api";
export default {
  name: "TopHeader",
  data() {
    return {
      nowDate: "", // 当前日期
      weather: "", //天气
      fullscreen: false,
    };
  },
  created() {
    console.log(123)
    indexgetWeather().then((res) => {
      this.weather =
        "\xa0\xa0\xa0" +
        res.data.temperature +
        "°C" +
        "\xa0\xa0\xa0" +
        res.data.weather;
    });
  },
  methods: {
    screen() {
      // 如果不允许进入全屏，发出不允许提示
      // if (!screenfull.enabled) {
      //   this.$message("您的浏览器不能全屏");
      //   return false;
      // }
      this.fullscreen = !this.fullscreen
      screenfull.toggle();
      // this.$message.success("全屏啦");
    },

    currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      let week = date.getDay(); // 星期
      let weekArr = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
    },
  },

  mounted() {
    this.currentTime();
  },

  // 销毁定时器

  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
    }
  },
};
</script>

<style lang="less" scoped>
#top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  .header-center-decoration {
    width: 40%;
    height: 60px;
    margin-top: 30px;
  }

  .header-left-decoration,
  .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 25px;
    font-weight: bold;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
  }
  .rihth-title {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    right: 12%;
    top: 55px;
    // transform: translateX(-50%);
  }
}
</style>
