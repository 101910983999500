<template>

    <div id="app">
      <datavindex v-if="type == 1" />
      <datavindex2 v-else-if="type == 2" />
    </div>

</template>

<script>
import datavindex from "../../components/datav/datav/index.vue";
import datavindex2 from "../../components/datav/datav2/index.vue";

export default {
  name: "datav",
  components: {
    datavindex,
    datavindex2,
  },
  data() {
    return {
      type: 1,
    };
  },
  created() {
    let { type } = this.$route.query;
    this.type = type;
    console.log(type)
  },
};
</script>

<style lang="less">
#app  , body, html{
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>
