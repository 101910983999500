<template>
  <div id="ranking-board">
    <div class="ranking-side duty">
      <!-- <div class="ranking-board-title">今日值班 {{ dutyCount }}  人</div> -->
      <div class="ranking-board-title">今日值班 2 人</div>
      <div class="carousel-div">
        <el-carousel :interval="5000" arrow="never" indicator-position="none">
          <el-carousel-item v-for="(item, index) in carousel_Data" :key="index">
            <div class="carousel-sty">
              <div class="carousel" v-for="(el, ins) in item" :key="ins">
                <img
                  :src="
                    el.avatar
                      ? el.avatar
                      : require('../../../assets/img/tx.jpeg')
                  "
                  alt=""
                />
                <p>{{ el.truename }}</p>
                <p>{{ el.phone }}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="ranking-side proportion">
      <div v-if="option.series[0].data.length != 0">
        <div class="ranking-board-title">隐患类型占比</div>
        <div class="active-ring">
          <div class="left-chart-2">
            <dv-charts class="lc2-chart" :option="option" />
          </div>
        </div>
      </div>

      <div class="ranking-board-title">隐患状态占比</div>
      <div class="active-ring" style="padding-top: 30px">
        <dv-capsule-chart
          :config="capsule_config"
          style="width: 300px; height: 150px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RankingBoard",
  props: {
    hdangerCate: {
      type: Array,
      default: function () {
        return [];
      },
    },

    hdangerStatus: {
      type: Array,
      default: function () {
        return [];
      },
    },
    duty: {
      type: Array,
      default: function () {
        return [];
      },
    },
    dutyCount: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  data() {
    return {
      carousel_Data: [
        [
          {
            truename: "王建国",
            phone: 15937014565,
          },
          {
            truename: "李凯",
            phone: 18723789489,
          },
        ],
      ],
      option: {
        series: [
          {
            type: "pie",
            radius: "50%",
            roseSort: false,
            data: [],
            insideLabel: {
              show: false,
            },

            outsideLabel: {
              formatter: "{name} {percent}%",
              labelLineEndLength: 20,
              style: {
                fill: "#fff",
              },
              labelLineStyle: {
                stroke: "#fff",
              },
            },
            roseType: true,
          },
        ],
        // color: [],
      },
      capsule_config: {
        showValue: true,
        data: [
          {
            name: "未管控",
            value: 167,
          },
          {
            name: "评估中",
            value: 67,
          },
          {
            name: "已管控",
            value: 123,
          },
          {
            name: "已验收",
            value: 123,
          },
        ],
      },
    };
  },

  watch: {
    hdangerCate: {
      handler(newValue, oldValue) {
        this.option.series[0].data = newValue;
        // this.config.data = newValue;
      },
      deep: true,
    },
    hdangerStatus: {
      handler(newValue, oldValue) {
        this.capsule_config.data = newValue;
        this.capsule_config = { ...this.capsule_config };
      },
      deep: true,
    },

    duty: function (newVal, oldVal) {
      var arr = [];
      for (var i = 0; i < newVal.length; i += 2) {
        arr.push(newVal.slice(i, i + 2));
      }
      // this.carousel_Data = arr;
    },
  },
};
</script>

<style lang="less" scoped>
.duty {
  height: 32%;
}
.carousel-div {
  height: 100%;
  padding-top: 50px;
}
.el-carousel {
  height: 100%;
}
/deep/ .el-carousel__container {
  height: 100%;
}
#ranking-board {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.proportion {
  margin-top: 13px;
  flex: 1;
}
.ranking-side {
  font-weight: bold;
  font-size: 20px;
  box-shadow: 0 0 3px blue inset;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  box-sizing: border-box;
  padding: 10px 40px;
}
.ranking-top {
  display: flex;
  justify-content: space-between;
}
.ranking-top-img {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  img {
    width: 10%;
    height: 50px;
  }
  p {
    margin-top: 10px;
    font-size: 14px;
  }
}
.ranking-top-img2 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    height: 50px;
  }
  p {
    font-size: 14px;
  }
}
.active-ring {
  display: flex;
  justify-content: center;
}

.lc2-chart {
  height: 280px;
  width: 400px;
}

.carousel-sty {
  display: flex;
  justify-content: space-between;
}
.carousel {
  margin: 0 auto;
  width: 80%;
  height: 150px;
  text-align: center;
  p {
    font-size: 15px;
    text-align: center;
  }
  img {
    width: auto;
    height: 60%;
  }
}
</style>
