<template>
  <div id="scroll-board">
    <div class="ranking-board-title">风险点管控台账</div>
    <dv-scroll-board v-if="config.data.length != 0" :config="config" />
  </div>
</template>

<script>
export default {
  name: "ScrollBoard",
  props: {
    dangerRecList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      config: {
        header: [],
        data: [],
        index: true,
        columnWidth: [50],
        align: ["center"],
        rowNum: 4,
        headerBGC: "#1981f6",
        headerHeight: 0,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },

  computed: {},
  watch: {
    dangerRecList: {
      handler(newValue, oldValue) {
        this.config.data = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
#scroll-board {
  // height: 32%;
  width: calc(50% - 10px);
  box-sizing: border-box;
  // margin-bottom: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  display: flex;
  flex-direction: column;
}
.ranking-board-title {
  padding-left: 10px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
</style>
