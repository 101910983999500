<template>
  <div id="scroll-board">
    <div class="ranking-board-title">网格化管控台账</div>
    <dv-scroll-board v-if="config.data.length != 0" :config="config" />
  </div>
</template>

<script>
export default {
  name: "ScrollBoard",

  props: {
    armyRecList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      config: {
        header: ["时间", "病害信息", "数量", "标段"],
        data: [],
        index: true,
        columnWidth: [50],
        align: ["center"],
        rowNum: 4,
        headerBGC: "#1981f6",
        headerHeight: 0,
        oddRowBGC: "rgba(0, 44, 81, 0.8)",
        evenRowBGC: "rgba(10, 29, 50, 0.8)",
      },
    };
  },
  watch: {
    armyRecList: {
      handler(newValue, oldValue) {
        // this.config.data = newValue;
        this.config.data = [
          ["2022-10-19", "楼梯损坏", "1", "2楼"],
          ["2022-10-10", "桌椅破损", "3", "5楼"],
          ["2022-10-3", "大门破损", "4", "2楼"],
          ["2022-10-1", "窗口破损", "5", "5楼"],
          ["2022-10-1", "小门破损", "5", "5楼"],
        ];
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
#scroll-board {
  // height: 32%;
  margin-top: 13px;

  width: calc(50% - 10px);
  box-sizing: border-box;
  // margin-bottom: 20px;
  background-color: rgba(6, 30, 93, 0.5);
  border-top: 2px solid rgba(1, 153, 209, 0.5);
  display: flex;
  flex-direction: column;
}
.ranking-board-title {
  padding-left: 10px;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
</style>
